// No users yet:
//bn - Bengali - Bangladesh
//ca - Catalan
//kn - Kanada - 1 pers
//mr - Marathi - 0 pers
//ur - Urdu - 0 pers
//hi - Hindi - 0 pers

module.exports = {
  defaultNS: "common",
  pages: {
    "*": ["common"],
    "/": ["home"],
    "rgx:/c": ["challenge"],
    "/challenge/[slug]": ["challenge"],
    "/lobby": ["lobby"],
    "/friends": ["friends"],
    "rgx:/leagues": ["leagues"],
    "rgx:/players": ["players"],
    "rgx:/puzzle": ["puzzle"],
    "rgx:/settings": ["settings"],
    "rgx:/register": ["register"],
    "rgx:/signIn": ["register"],
    "rgx:/analysis": ["analysis"],
    "rgx:/profile": ["profile"],
    "rgx:/blog": ["category"],
  },
  locales: [
    "en",
    "fr",
    "sv",
    "pt",
    "ru",
    "it",
    "es",
    "de",
    "af",
    "ar",
    "az",
    "be",
    "bg",
    "bn",
    "bs",
    "ca",
    "cs",
    "da",
    "el",
    "et",
    "fa",
    "fi",
    "gl",
    "gu",
    "he",
    "hi",
    "hr",
    "hu",
    "hy",
    "id",
    "is",
    "ja",
    "ka",
    "kk",
    "kn",
    "ko",
    "lt",
    "lv",
    "ml",
    "mn",
    "mr",
    "nl",
    "nb",
    "pl",
    "ps",
    "pt-PT",
    "ro",
    "sk",
    "sl",
    "sr",
    "sw",
    "th",
    "tk",
    "tr",
    "uk",
    "ur",
    "uz",
    "vi",
    "zh-CN",
    "zh-TW",
  ],
  defaultLocale: "en",
};
