import { gaEvent } from "./lib/ga";
import { userStore } from "./ducks/user";

export function trackPwaInstalls() {
  window.addEventListener("appinstalled", () => {
    // If visible, hide the install promotion

    const userId = userStore.getState().id;
    gaEvent({
      action: "pwaInstall",
      category: "pwa",
      label: userId,
    });
  });
}
