const sounds = {
  gamePlay: {
    src: "/sounds/metal_gong3.mp3",
    volume: 0.5,
    html5: true,
  },
  take: {
    src: "/sounds/take2.mp3",
    volume: 0.8,
    html5: true,
  },
  move: {
    src: "/sounds/move2.mp3",
    volume: 1.0,
    html5: true,
  },
  chat: {
    src: "/sounds/bubble.mp3",
    volume: 0.2,
  },
};

// Initialize AudioContext
let audioCtx;
const audioBuffers = {};

if (typeof window !== "undefined") {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  audioCtx = new AudioContext();

  async function preloadSounds() {
    for (const [name, config] of Object.entries(sounds)) {
      const response = await fetch(config.src);
      const arrayBuffer = await response.arrayBuffer();
      audioCtx.decodeAudioData(
        arrayBuffer,
        (audioBuffer) => {
          audioBuffers[name] = {
            audioBuffer: audioBuffer,
            volume: config.volume,
          };
        },
        (error) => console.error("Error with decoding audio data", error)
      );
    }
  }

  // Call this function when your application starts
  preloadSounds();
}

export function playSound(name: string) {
  if (!window.userHasInteracted) {
    console.log("not interacted");
  }

  if (!audioBuffers[name]) {
    console.log("no buffers");
  }

  if (!window.userHasInteracted || !audioBuffers[name]) {
    console.log("User has not interacted yet, or sound not loaded.");
    return;
  }

  const source = audioCtx.createBufferSource();
  source.buffer = audioBuffers[name].audioBuffer;
  const gainNode = audioCtx.createGain();
  gainNode.gain.value = audioBuffers[name].volume;

  // Connect the source to the gain node and the gain node to the destination
  source.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  // Play the sound
  if (audioCtx.state === "suspended") {
    audioCtx.resume();
  }
  source.start(0);
}
