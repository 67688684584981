import router from "next/router";
import { addSentChallenge, removeChallenge } from "../../ducks/challenges";
import { lobbyStore, setTab } from "../../ducks/lobby";
import { api } from "../../services/api";
import { settingsStore } from "../../ducks/settings";
import { convertToNumber } from "../../util/util";
import { ChallengeWs, DailyLobbyWs } from "../../services/wsOutRoutes";
import { generateGameHistory } from "../daily/cGamePlayActions";
import { PrivateChallenge } from "@chesshotel/models";
import {
  AcceptedPrivateChallenge,
  RematchChallenge,
  UserDailyGame,
} from "@chesshotel/types";
import { DAILY_GAMES_TAB } from "@chesshotel/constants";

export function joinDailyGame(id) {
  const { leaguePlay } = lobbyStore.getState();

  api.post("/daily/invite/join", { id, leaguePlay }).then((res) => {
    const game: UserDailyGame = res.data.game;
    if (game) {
      import("../../ducks/daily").then(async (daily) => {
        daily.setGame(await generateGameHistory(game));
        daily.removeDailyInvite(id);
      });

      DailyLobbyWs.joinGame({
        id,
        gameId: game.id,
        opponentUsername: game.opponent.username,
        opponentUserId: game.opponent.id,
      });

      if (game.playingWhite) {
        router.push(`/daily/game/${game.id}`);
      } else {
        setTab(DAILY_GAMES_TAB);
      }
    }
  });
}

export type NewChallenge = Omit<PrivateChallenge, "id" | "created" | "slug">;

export async function sendPrivateChallenge(challenge: NewChallenge) {
  const res = await api.post("/challenge", challenge);
  const challengeDB = res.data;

  if (challengeDB && res.status != 202) {
    addSentChallenge(challengeDB);

    ChallengeWs.sendChallenge(challengeDB);
  }
  return challengeDB;
}

export function acceptLiveChallenge(challenge: PrivateChallenge) {
  const { slug } = challenge;

  api.post("/challenge/delete", { slug }).then((res) => {
    removeChallenge(challenge.id);

    ChallengeWs.acceptLiveChallenge(challenge);
  });
}

export function acceptDailyChallenge(
  challenge: PrivateChallenge | RematchChallenge
) {
  const { id } = challenge;
  api.post("/challenge/accept", { id }).then((res) => {
    const game = res.data.game;
    import("../../ducks/daily").then(async (daily) => {
      daily.setGame(await generateGameHistory(game));
    });
    removeChallenge(id);

    const acceptedChallenge: AcceptedPrivateChallenge = {
      ...challenge,
      gameId: game.id,
    };

    ChallengeWs.acceptDailyChallenge(acceptedChallenge);

    router.push(`/daily/game/${game.id}`);
  });
}

export function createInvite({ modeOption, timeOption, leaguePlay }) {
  const { maxOpponentRating, minOpponentRating } = settingsStore.getState();
  const variantId = convertToNumber(modeOption);
  const turnLength = convertToNumber(timeOption);
  const dailyGameOptions = {
    maxOpponentRating,
    minOpponentRating,
    variantId,
    turnLength,
    leaguePlay,
  };
  api.post("/daily/invite/create", dailyGameOptions).then((res) => {
    if (res.status == 200) {
      DailyLobbyWs.addInvite(res.data.invite);
    }
  });
}
