import { VariantTypes } from "@chesshotel/constants";
import { UserDailyGame, SharedPlayer } from "@chesshotel/types";
import { RematchChallenge } from "@chesshotel/types";
import { updateLeagueScores } from "../../ducks/leagues";
import { api } from "../../services/api";
import { DailyGameWs } from "../../services/wsOutRoutes";
import { playersStore } from "../../ducks/players";
// import { setGame } from "../../ducks/daily";

export interface UserDailyGameWithHistory extends UserDailyGame {
  positions: any;
  moves: any[];
  positionIndex: number;
  rematchChallenge?: RematchChallenge;
}

export function updateOpponentStatus(userId: number, online: boolean) {
  import("../../ducks/daily").then((corr) => {
    const { opponentStatus } = corr.dailyStore.getState();
    if (opponentStatus.userId === userId) {
      corr.setOpponentStatus({ userId, online });
    }
  });
}

export function initOpponentStatus(userId: number) {
  const { players } = playersStore.getState();
  const online = !!players.find((player: SharedPlayer) => {
    return player.id === userId;
  });
  import("../../ducks/daily").then((corr) => {
    corr.setOpponentStatus({ userId, online });
  });
}

export async function generateGameHistory(
  game: UserDailyGame
): Promise<UserDailyGameWithHistory> {
  const { Chess } = await import("@chesshotel/chess.js");
  const chess =
    game.variantId === VariantTypes.VARIANT_CHESS
      ? Chess()
      : Chess(game.startFen, VariantTypes.VARIANT_CHESS960);

  const firstPos =
    game.variantId === VariantTypes.VARIANT_CHESS ? chess.fen() : game.startFen;

  const movesArr = game.pgn ? game.pgn.split(" ") : [];
  const positions = movesArr.map((move) => {
    chess.move(move);
    return chess.fen();
  });
  positions.unshift(firstPos);
  const moves = chess.history({ verbose: true });

  const positionIndex = moves.length;
  return { ...game, positions, moves, positionIndex };
}

export async function initGame(serverGame: UserDailyGame) {
  const game = await generateGameHistory(serverGame);

  import("../../ducks/daily").then((daily) => {
    daily.setGame(game);
  });
  if (game.leaguePlay && game.resultId != null) {
    updateLeagueScores(game.leagueScoreResult);
  }
}

export async function getGame(gameId: number) {
  const res = await api.get(`/daily/game/${gameId}`);
  await initGame(res.data.game);
}

export function sendMove(id, move, fen, setNewMove) {
  api.post(`/daily/game/send-move`, { id, move }).then(async (res) => {
    const game = res.data.game;
    await initGame(res.data.game);
    setNewMove(null);

    const { id, variantId, lastMoveTime, opponent, turnLength } = game;

    const dailyMove = {
      gameId: id,
      variantId,
      fen,
      opponentUsername: opponent.username,
      opponentId: opponent.id,
      turnLength,
      move,
      lastMoveTime,
    };

    DailyGameWs.sendMove(dailyMove);
  });
}
